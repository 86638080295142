import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Box, Text, Heading, Flex } from '@chakra-ui/react';

import StarRating from '@/components/StarRating';
import { SchoolInformation } from '@/components/Outline';

import { pages } from '@/utils/pages';

const Outline = ({
  outline: {
    id,
    course_name,
    average_rating,
    count_rating,
    school_name,
    professor_name,
    grade,
  },
}) => {
  const router = useRouter();

  const handleSelectOutline = id => {
    router.push(pages.outlinePath(id));
  };

  return (
    <Box
      w='full'
      minW={60}
      h='full'
      bg='white'
      rounded={16}
      p={3}
      data-testid='outline-card'
      cursor='pointer'
      onClick={() => handleSelectOutline(id)}
    >
      <Heading color='gray.900' fontSize='lg' my='6px'>
        {course_name}
      </Heading>
      <SchoolInformation
        professorName={professor_name}
        schoolName={school_name}
        grade={grade}
        showAllField={false}
      />
    </Box>
  );
};

Outline.defaultProps = {
  outline: {
    course: {},
    school: {},
    professor: {},
    user: {},
    grade: '',
    count_rating: 0,
    average_rating: 0,
  },
};

Outline.propTypes = {
  outline: PropTypes.shape({
    id: PropTypes.number,
    course: PropTypes.object,
    course_period: PropTypes.string,
    count_rating: PropTypes.number,
    average_rating: PropTypes.number,
    school: PropTypes.object,
    professor: PropTypes.object,
    user: PropTypes.object,
    grade: PropTypes.string,
  }),
};

export default Outline;
